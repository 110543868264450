import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import ContactUsForm from '../components/landing/ContactUsForm'

const InfoSection = ({ title, text }) => (
  <div className="methodology-info">
    <h2 className="title is-5 has-text-weight-medium is-marginless">{title}</h2>
    <div className="decorator-line" />
    <p className="description block-description" dangerouslySetInnerHTML={{ __html: text }} />
  </div>
)

export const MethodologyPageTemplate = ({ title, infoSections }) => (
  <div className="content page-content methodology-page">
    <section className="hero is-family-primary has-text-white pulse-hero">
      <div className="hero-body">
        <div className="container has-text-centered">
          <h2 className="title is-size-1 is-size-2-mobile has-text-weight-medium has-text-white">
            {title}
          </h2>
        </div>
      </div>
    </section>
    <div className="container">
      <div className="columns is-variable is-6-tablet is-2-mobile">
        <div className="column is-half-tablet is-full-mobile">
          {infoSections
            .filter((sec, i) => i % 2 === 0)
            .map(sec => (
              <InfoSection key={sec.title} {...sec} />
            ))}
        </div>
        <div className="column is-half-tablet is-full-mobile">
          {infoSections
            .filter((sec, i) => i % 2 === 1)
            .map(sec => (
              <InfoSection key={sec.title} {...sec} />
            ))}
        </div>
      </div>
      <p className="description block-description bottom-text">
        <sup>1</sup>Jeffrey Pomerantz and D. Christopher Brooks,&nbsp;
        <a
          target="_blank"
          href="https://library.educause.edu/resources/2017/10/ecar-study-of-undergraduate-students-and-information-technology-2017"
        >
          2017 ECAR Study of Undergraduate Students and Information Technology
        </a>
        , EDUCAUSE Center for Analysis and Research, 2017
      </p>
    </div>
    <ContactUsForm />
  </div>
)

MethodologyPageTemplate.propTypes = {
  title: PropTypes.string,
  infoSections: PropTypes.array,
}

const MethodologyPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <MethodologyPageTemplate title={frontmatter.title} infoSections={frontmatter.infoSections} />
    </Layout>
  )
}

MethodologyPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default MethodologyPage

export const methodologyPageQuery = graphql`
  query MethodologyPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        infoSections {
          title
          text
        }
      }
    }
  }
`

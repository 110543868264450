import React from 'react'
import PropTypes from 'prop-types'

const ContactUsForm = () => {
  return (
    <form method="post" action="/methodology">
      <section className="section contact-form-section">
        <div class="columns">
          <div class="column is-full-mobile is-full-tablet is-half-desktop">
            <p className="title has-text-weight-medium is-uppercase">CONTACT US</p>
            <div className="decorator-line" />
            <h4 className="title is-4 has-text-weight-medium has-text-white">
              Learn more about survey research with College Pulse
            </h4>
            <div className="field">
              <div className="control">
                <input
                  className="input"
                  type="text"
                  id="firstName"
                  name="firstName"
                  placeholder="First Name"
                />
              </div>
            </div>
            <div className="field">
              <div className="control">
                <input
                  className="input"
                  type="text"
                  id="lastName"
                  name="lastName"
                  placeholder="Last Name"
                />
              </div>
            </div>
            <div className="field">
              <div className="control">
                <input className="input" type="email" id="email" name="email" placeholder="Email" />
              </div>
            </div>
            <div className="field">
              <div className="control">
                <input
                  className="input"
                  type="text"
                  id="jobTitle"
                  name="jobTitle"
                  placeholder="Job Title"
                />
              </div>
            </div>
            <div className="field">
              <div className="control">
                <input
                  className="input"
                  type="text"
                  id="company"
                  name="company"
                  placeholder="Company"
                />
              </div>
            </div>
            <div className="field">
              <label for="message" className="is-size-7 has-text-weight-medium has-text-grey-light">
                How can College Pulse help you?
              </label>
              <div className="control">
                <textarea className="textarea" id="message" name="message" />
              </div>
            </div>
            <input
              className="button submit-button is-dark is-radiusless"
              type="submit"
              value="SUBMIT"
            />
          </div>
        </div>
      </section>
    </form>
  )
}

ContactUsForm.propTypes = {}

export default ContactUsForm
